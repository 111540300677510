import React from 'react';
import { Link } from "react-router-dom";

import './NavRodape.css';

import imgapple from '../img/apple.png'
import imggoogle from '../img/google.png'

function NavRodape() {
    return (
        <div className="rodape">
            <div className="navMenu">

                <div className="navMenuItem">
                    <Link className="navlink" to="/Sobre">SOBRE</Link>
                </div>
       
                <div className="navMenuItem">
                    <Link className="navlink" to="/Suporte">SUPORTE</Link>
                </div>
                <div className="navMenuItem">
                    <Link className="navlink" to="/Termos">TERMOS</Link>
                </div>
                <div className="navMenuItem">
                    <Link className="navlink" to="/Politicas">POLÍTICAS</Link>
                </div>
                {/*


         <div className="navMenuItem">
                    <Link className="navlink" to="/Assinatura">ASSINATURA</Link>
                </div>

*/

                }
       
         
         
            </div>


            <div className="navMenu">
                <div className="navMenuItem2">
                    <a href="https://apps.apple.com/br/app/meupastor-tv/id6450556202" target="_blank" rel="noopener noreferrer" >
                        <img src={imgapple} className="navicon" alt="apple store" />
                    </a>

                </div>
                <div  >

                    <a href="https://play.google.com/store/apps/details?id=tv.meupastorapp" target="_blank" rel="noopener noreferrer" >
                        <img src={imggoogle} className="navicon" alt="play store" />
                    </a>

                </div>

            </div>
            <div className="navcop">Copyright © 2020 meupastor TV - Todos os direitos reservados</div>
            <div className="navcop">

            <br></br>
        

        <a
  href="https://wa.me/5521998851057?text=Ol%C3%A1%21%20Gostaria%20de%20receber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20MeuPastor%20TV.%20Pode%20me%20ajudar%3F"
  target="_blank"
  rel="noopener noreferrer"
  className="whatsapp-btn"
>
  <img
    src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
    alt="WhatsApp"
    className="whatsapp-icon"
  />
  Fale conosco pelo WhatsApp
</a>


        <br></br>
        
        <br></br>
        </div>

        </div>

    );
}

export default NavRodape;